import {makeAutoObservable} from "mobx"
import {getStatistics} from "../../../services/ApiService"
import moment from "moment"
import { closeModalFn } from "../../../utils/controllers/ModalController"

class StatisticsStore {
    statistics = []
    filteredStatistics = []
    userNames = []
    pageSize = 100
    pageNumber = 0
    isSearch = false
    isReady = false

    constructor({filtersStore}) {
        makeAutoObservable(this)
        this.filterStore = filtersStore
    }

    load = () => {
        getStatistics().then((res) => {
            
            const collection = []
            this.userNames = []

            Object.keys(res).forEach((date) => {
                Object.keys(res[date]).forEach((name) => {
                    const newData = {}
                    if (this.userNames.every((el) => el !== name)) {
                        this.userNames.push(name)
                    }

                    newData["name"] = name
                    newData["date"] = date
                    newData["fileTotal"] = res[date][name][1] || 0
                    newData["mailTotal"] = res[date][name][2] || 0
                    newData["siteTotal"] = res[date][name][3] || 0
                    collection.push(newData)
                })
            })
            collection.sort((a, b) => (moment(a.date).isAfter(b.date) ? -1 : moment(a.date).isBefore(b.date) ? 1 : 0))
            this.statistics = collection
            this.isReady = true
            closeModalFn['progress-backdrop']()
        })
    }

    get selectedCollection() {
        return this.isSearch ? this.filteredStatistics : this.statistics
    }

    get userNamesGetter() {
        // slice тут нужен что бы сделать копию массива, так как sort change array in place, ругался сам mobX
        return this.userNames.slice().sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) {
                return -1
            }
            if (a.toLowerCase() > b.toLowerCase()) {
                return 1
            }
            return 0
        })
    }

    onSearch = () => {
        const {from, to, names} = this.filterStore.data
        if (from || to || names.length) {
            this.filteredStatistics = this.statistics.filter((data) => {
                let validItem = true

                if (names.length && !names.some((name) => name === data.name)) validItem = false

                if (
                    from &&
                    !moment(data.date).isAfter(moment(from)) &&
                    from &&
                    !moment(data.date).isSame(moment(from).format("YYYY-MM-DD"))
                ) {
                    validItem = false
                }

                if (
                    to &&
                    !moment(data.date).isBefore(moment(to)) &&
                    to &&
                    !moment(data.date).isSame(moment(to).format("YYYY-MM-DD"))
                ) {
                    validItem = false
                }

                if (validItem) {
                    return data
                }
            })

            this.isSearch = true
        } else {
            this.filteredStatistics = this.statistics
        }
        return Promise.resolve().then(() => {closeModalFn['progress-backdrop']()})
    }

    onClear = () => {
        this.filterStore.clear()
        this.isSearch = false
        this.filteredStatistics = []
        this.onSearch()
    }

    onPageChange = (e, number) => {
        this.pageNumber = number
    }

    onPageSizeChange = (e) => {
        this.pageSize = e.target.value
    }
}

export default StatisticsStore

import React, {useRef, useState} from "react"
import {observer} from "mobx-react"
import StatisticsStore from "./stores/StatisticsStore"
import StatisticsTable from "./components/StatisticsTable"
import StatisticsFilters from "./components/StatisticsFilters"
import StatisticsFilterStore from "./stores/StatisticsFilterStore"
import Box from "@mui/material/Box"
import ProgressBackdrop from "../../components/ProgressBackdrop/ProgressBackdrop"

function Statistics() {
    const [filtersStore] = useState(() => new StatisticsFilterStore())
    const [store] = useState(() => new StatisticsStore({filtersStore}))

    const {load} = store
    useState(() => {
        load()
    })

    return store.isReady ? (
        <>
            <Box mb={2}>
                <StatisticsFilters filtersStore={filtersStore} store={store} />
            </Box>
            <StatisticsTable store={store} />
        </>
    ) : (
        <ProgressBackdrop isOpen={true} local={true} />
    )
}

export default observer(Statistics)

import CustomTable from '../../../utils/components/CustomTable/CustomTable'
import React, { useMemo } from 'react'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'
import Tooltip from '@mui/material/Tooltip'
import { Box, Typography } from '@mui/material'

function StatisticsTable({ store }) {
    const intl = useIntl()
    const { pageSize, pageNumber, onPageChange, onPageSizeChange, selectedCollection } = store
    const columns = useMemo(
        () => [
            {
                field: 'date',
                headerName: intl.formatMessage({ id: 'Дата' }),
                renderCellValue: (params) => moment(params.date).format('DD.MM.YYYY'),
                minWidth: '180px',
                justifyContent: 'flex-start',
                headerStart: true,
                padding: '0 0 0 45px',
            },
            {
                field: 'name',
                headerName: intl.formatMessage({ id: 'Пользователь' }),
                renderCellValue: (params) => {
                    if (params.name.length <= 30) {
                        return (
                            <Typography
                                sx={{
                                    fontWeight: '400',
                                    fontSize: '14px'
                                }}
                            >
                                {params.name}
                            </Typography>
                        )
                    }
                    return (
                        <Tooltip title={params.name} placement={'top-start'} TransitionProps={{ timeout: 0 }}>
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    cursor: 'default',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    color: 'inherit',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '400',
                                    }}
                                >
                                    {params.name.slice(0, 40) + '...'}
                                </Typography>
                            </Typography>
                        </Tooltip>
                    )
                },
                headerStart: true,
                minWidth: '190px',
                justifyContent: 'flex-start',
            },
            {
                field: 'fileTotal',
                headerName: intl.formatMessage({ id: 'Файл' }),
                minWidth: '130px',
                renderCellValue: (params) => params.fileTotal,
            },
            {
                field: 'siteTotal',
                headerName: intl.formatMessage({ id: 'CRM' }),
                minWidth: '130px',
                renderCellValue: (params) => params.siteTotal,
            },
            // {
            //     field: 'mailTotal',
            //     headerName: intl.formatMessage({ id: 'Яндекс форма или электронное письмо' }),
            //     minWidth: '130px',
            //     renderCellValue: (params) => params.mailTotal,
            //     headerStart: true,
            // },
            {
                field: 'total',
                headerName: intl.formatMessage({ id: 'Всего' }),
                minWidth: '130px',
                renderCellValue: (params) => params.mailTotal + params.siteTotal + params.fileTotal,
            },
        ],
        []
    )

    return (
        <CustomTable
            rows={selectedCollection}
            columns={columns}
            pageSize={pageSize}
            page={pageNumber}
            total={selectedCollection.length}
            onPageChange={onPageChange}
            paginateCollection
            onPageSizeChange={onPageSizeChange}
            doublePagination={true}
        />
    )
}

export default observer(StatisticsTable)

import {gState} from "../utils/controllers/GlobalStateController"

class DatesStore {
    months = [
        {value: 1, key: gState["intl"].formatMessage({id: "январь"})},
        {value: 2, key: gState["intl"].formatMessage({id: "февраль"})},
        {value: 3, key: gState["intl"].formatMessage({id: "март"})},
        {value: 4, key: gState["intl"].formatMessage({id: "апрель"})},
        {value: 5, key: gState["intl"].formatMessage({id: "май"})},
        {value: 6, key: gState["intl"].formatMessage({id: "июнь"})},
        {value: 7, key: gState["intl"].formatMessage({id: "июль"})},
        {value: 8, key: gState["intl"].formatMessage({id: "август"})},
        {value: 9, key: gState["intl"].formatMessage({id: "сентябрь"})},
        {value: 10, key: gState["intl"].formatMessage({id: "октябрь"})},
        {value: 11, key: gState["intl"].formatMessage({id: "ноябрь"})},
        {value: 12, key: gState["intl"].formatMessage({id: "декабрь"})}
    ]

    secondDeclensionOfMonths = [
        {value: 1, key: gState["intl"].formatMessage({id: "января"})},
        {value: 2, key: gState["intl"].formatMessage({id: "февраля"})},
        {value: 3, key: gState["intl"].formatMessage({id: "марта"})},
        {value: 4, key: gState["intl"].formatMessage({id: "апреля"})},
        {value: 5, key: gState["intl"].formatMessage({id: "мая"})},
        {value: 6, key: gState["intl"].formatMessage({id: "июня"})},
        {value: 7, key: gState["intl"].formatMessage({id: "июля"})},
        {value: 8, key: gState["intl"].formatMessage({id: "августа"})},
        {value: 9, key: gState["intl"].formatMessage({id: "сентября"})},
        {value: 10, key: gState["intl"].formatMessage({id: "октября"})},
        {value: 11, key: gState["intl"].formatMessage({id: "ноября"})},
        {value: 12, key: gState["intl"].formatMessage({id: "декабря"})}
    ]

    getMonthName = (month, secondDeclension = false, capitalLetter = false) => {
        const monthsArray = secondDeclension ? this.secondDeclensionOfMonths : this.months
        let monthData = monthsArray.find((el) => el.value === month || el.value === Number(month))
        if (capitalLetter) {
            monthData.key = monthData.key[0].toUpperCase() + monthData.key.slice(1)
        }
        return monthData ? monthData.key : month
    }
}

export default DatesStore

import React from "react"
import {observer} from "mobx-react"
import {useIntl} from "react-intl"
import NewFilters from "../../../components/NewFilters/NewFilters"
import RequestFilterChip from "../../RequestsManagement/components/NewRequestFilters/components/RequestFilterChip"

function StatisticsFilters({filtersStore, store}) {
    const intl = useIntl()
    const {userNamesGetter, onSearch, onClear} = store
    
    const filtersData = [
        [
            [
                {
                    type: "date",
                    propName: "from"
                },
                {
                    type: "date",
                    propName: "to"
                }
            ],
            {
                type: "select",
                multiple: true,
                name: intl.formatMessage({id: "Пользователи"}),
                propName: "names",
                collection: userNamesGetter
            }
        ]
    ]
    const handleSearch = () => {
        filtersStore.setSelectedFilters()
        onSearch()
        return Promise.resolve()
    }

    const handleDeleteChip = (propName, multiselectValue) => {
        filtersStore.deleteFilter(propName, multiselectValue)
        handleSearch()
    }
    const handleClear = () => {
        filtersStore.clear()
        onClear()
    }

    const setChip = (params, withAction) => <RequestFilterChip {...params} onClick={withAction && handleDeleteChip} />
    return (
        <NewFilters
            totalCollectionLength={store.selectedCollection.length}
            filtersStore={filtersStore}
            onSearch={handleSearch}
            onClear={handleClear}
            filtersData={filtersData}
            setChip={setChip}
            title={intl.formatMessage(
                {
                    id: "Найдено записей {orderValue}",
                    defaultMessage: "Найдено записей {orderValue}"
                },
                {
                    orderValue: store.selectedCollection.length
                }
            )}
        />
    )
}

export default observer(StatisticsFilters)

import React from "react"
import CircularProgress from "@mui/material/CircularProgress"
import progressStyles from "../styles/progressStyles"
import {css} from "@emotion/css"
import {getStyles} from "../../../utils/utils/commonUtils"

function PrimaryCircularProgress(props) {
    const classes = getStyles(progressStyles)

    return (
        <div className={css(classes.root)}>
            <CircularProgress
                variant="determinate"
                className={css(classes.bottom)}
                size={props.size || 40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{animationDuration: '367ms'}}
                className={css(classes.top)}
                classes={{
                    circle: css(classes.circle),
                }}
                size={props.size || 40}
                thickness={4}
                {...props}
            />
        </div>
    )
}

export default PrimaryCircularProgress
import FormStore from "../../../utils/stores/FormStore"

const initState = {
    from: null,
    to: null,
    names: []
}

const validateData = {
    names: {required: true}
}

class StatisticsFilterStore extends FormStore {
    constructor() {
        super({
            initState,
            validateData
        })
        this.dateNames = [["from", "to"]]
    }

    clear = () => {
        this.setData(initState)
        this.clearSelectedFilters()
    }
}

export default StatisticsFilterStore
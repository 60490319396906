import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { getStyles } from '../../../../../utils/utils/commonUtils'
import { getComp } from '../../../../../utils/utils/DI'
import Box from '@mui/material/Box'
import { css } from '@emotion/css'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl/lib'
import Chip from '../../../../../components/Chips/Chip'
import { useIntl } from 'react-intl'
import chipsStyles from '../../../../../components/Chips/styles/chipsStyles'
import moment from 'moment'
import DatesStore from '../../../../../stores/DatesStore'

function RequestFilterChip({ propName, value, onClick }) {
    const classes = getStyles(chipsStyles)
    const intl = useIntl()
    const {
        getStatusImage,
        getStatusName,
        getDataCheckingStatusName,
        getFinancialSourceElement,
        getAgreementStatusData,
    } = getComp('StatusesStore')
    const { getCategoryName } = getComp('CategoriesStore')
    const { getName } = getComp('AuthorsStore')
    const { getDealerBuyAid } = getComp('DealersStore')
    const { getInstallationReasonName } = getComp('InstallationReasonStore')
    const { getSourceName } = getComp('SourcesStore')
    const { getSenderName } = getComp('SendersStore')
    const [datesStore] = useState(new DatesStore())
    //  value === '' ? (value = 'Без приоритета') : value

    const setChipAction = useCallback(() => {
        return onClick ? () => onClick(propName, value === 'Без приоритета' ? '' : value) : null
    }, [value])

    const setValue = useCallback(() => {
        switch (propName) {
            case 'statuses':
            case 'status': {
                return (
                    <Box className={'aln-center'}>
                        <img alt={''} src={getStatusImage(value)} className={css(classes.statusIcon)} />
                        <Typography variant={'body1'} sx={classes.chipText}>
                            {getStatusName(value)}
                        </Typography>
                    </Box>
                )
            }

            case 'financialSourceIds': {
                const data = getFinancialSourceElement(value)
                return (
                    <Box className={'aln-center'}>
                        <img alt={''} src={data.imageSrc} className={css(classes.statusIcon)} />
                        <Typography variant={'body1'} sx={classes.chipText}>
                            {data.name}
                        </Typography>
                    </Box>
                )
            }
            case 'dataCheckingStatus': {
                return getDataCheckingStatusName(value)
            }
            case 'agreementCheckingStatus': {
                const data = getAgreementStatusData(value)
                return (
                    <Box className={'aln-center'}>
                        <img alt={''} src={data.imageSrc} className={css(classes.statusIcon)} />
                        <Typography variant={'body1'} sx={classes.chipText}>
                            {data.name}
                        </Typography>
                    </Box>
                )
            }
            case 'source': {
                return getSourceName(value)
            }
            case 'categories': {
                return getCategoryName(value)
            }
            case 'from': {
                return intl.formatMessage({ id: 'Создана' }) + ' ' + value
            }
            case 'installedFrom': {
                return intl.formatMessage({ id: 'Установлено' }) + ' ' + value
            }
            case 'subjectList':
            case 'authorId': {
                return getName(value) ? getName(value).name : ''
            }
            case 'sender': {
                return getSenderName(value)
            }
            case 'dealerIds':
            case 'dealer': {
                return getDealerBuyAid(value) ? getDealerBuyAid(value).displayName : ''
            }
            case 'reasonId': {
                let result = getInstallationReasonName(value)
                if (result === 'Замена') {
                    result = result + ' (Горыныч)'
                }
                return result
            }
            case 'hasDuplicates': {
                return <FormattedMessage id={'Повторное обращение'} />
            }
            case 'priority': {
                return <FormattedMessage id={'Приоритет'} />
            }
            case 'reportMonth': {
                return datesStore.getMonthName(value, false, true)
            }
            case 'subpage': {
                return value.name
            }
            case 'isExpired': {
                return <FormattedMessage id={'Просроченные заявки'} />
            }
            case 'isSuspicious': {
                return <FormattedMessage id={'Отмеченные как подозрительные'} />
            }
            default: {
                return value
            }
        }
    }, [value])

    return <Chip action={setChipAction()} value={setValue()} />
}

export default observer(RequestFilterChip)

const progressStyles = theme => ({
    root: {
        position: "relative",
        zIndex: 1501
    },
    bottom: {
        color: "#FFF",
    },
    top: {
        color: theme.palette.primary.main,
        animationDuration: "550ms",
        position: "absolute",
        left: 0,
    },
    circle: {
        strokeLinecap: "round",
    },
    backdrop: {
        zIndex: "1500",
        backgroundColor: theme.palette.background.default + "85",
    }
})

export default progressStyles
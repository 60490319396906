import React from "react"
import {observer} from "mobx-react"
import Backdrop from "@mui/material/Backdrop"
import progressStyles from "./styles/progressStyles"
import PrimaryCircularProgress from "./compoenents/PrimaryCircularProgress"
import {getStyles} from "../../utils/utils/commonUtils"
import {isModalOpen} from "../../utils/controllers/ModalController"


function ProgressBackdrop({isOpen, local}) {
    const classes = getStyles(progressStyles)
    return (
        <Backdrop open={local ? isOpen : !!isModalOpen["progress-backdrop"]} sx={classes.backdrop}>
            <PrimaryCircularProgress color="inherit"/>
        </Backdrop>
    )
}

export default observer(ProgressBackdrop)